'use strict';

Gri.module({
  name: 'wow',
  ieVersion: null,
  $el: $('.wow'),
  container: '.wow',
  fn: function () {
    new WOW().init();
  }
});
